import React, {useState, useRef, useEffect, useMemo} from 'react';
import AgoraRTC from "agora-rtc-sdk-ng";

import './style.scss';
import MessageChat from '../MessageChat';
import lot from '../../assets/images/57_92e503bd-f35e-485c-8a38-485074703b9c_2048x2048.jpeg';
import lot2 from '../../assets/images/57_f72ff2ac-6887-4573-9f2c-f5944d933851_2048x2048.jpeg';

const VideoStream = () => {
  const client = useRef(AgoraRTC.createClient({mode: "live", codec: "vp8"}))
  const ref = useRef(null);
  const localName = useRef(null);

  const [role, setRole] = useState(null);
  const [seconds, setSeconds] = useState(300);
  const [localTrackData, setLocalTrackData] = useState(null);
  const [showContentBlock, setShowContentBlock] = useState(false);
  const [channelName] = useState('demo_channel');
  const [userUid, setUserUid] = useState(null);
  const [leave, setLeave] = useState(false);
  const [audienceLatency] = useState(2)
  const [remoteUsers, setRemoteUsers] = useState({});
  const [joinSession, setJoinSession] = useState(false);

  const join = async (userRole) => {
    setJoinSession(true);
    if (userRole === 'audience') {
      client.current.setClientRole(userRole, {level: audienceLatency});
      client.current.on('user-published', handleUserPublished);
      client.current.on('user-unpublished', handleUserUnpublished);
    }

    const uid =
      await client.current.join(
        process.env.REACT_APP_AGORA_ID || '706ae9e9433a43d3a8ad75fc2166450f',
        channelName || '123',
        null,
        userUid || 12345,
      );

    if (userRole === 'host') {
      client.current.setClientRole(userRole);
      let localTrack = {
        audioTrack: null,
        videoTrack: null
      };
      localTrack.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      localTrack.videoTrack = await AgoraRTC.createCameraVideoTrack();
      localTrack.videoTrack.play('local-player');
      setLocalTrackData(localTrack);
      localName.current.textContent = `Live stream from: ${uid}`;
      await client.current.publish(Object.values(localTrack));

    }
  };

  window.onunload = () => isHandlerLeave();

  const subscribe = async (user, mediaType) => {
    const uid = user.uid;
    await client.current.subscribe(user, mediaType);

    if (mediaType === 'video') {
      const player = document.createElement('div');
      player.innerHTML = `
        <div id="player-wrapper-${uid}">
            <p class="player-name">Live stream from: ${uid}</p>
            <div id="player-${uid}" class="player"></div>
        </div>
      `;
      document.querySelector('.remove-playerlist').appendChild(player);
      user.videoTrack.play(`player-${uid}`, {fit: 'contain'});
    }

    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  }

  const handleUserPublished = (user, mediaType) => {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
  }

  function handleUserUnpublished(user) {
    const id = user.uid;
    delete remoteUsers[id];
    if (document.querySelector(`#player-wrapper-${id}`)) {
      document.querySelector(`#player-wrapper-${id}`).remove();
    }
  }

  const isHandlerChooseRole = async (e) => {
    e.preventDefault();
    setRole(e.target.value);
    setShowContentBlock(true);
    await join(e.target.value);
  };

  const isHandlerLeave = async (e) => {
    e.preventDefault();
    for (let trackName in localTrackData) {
      const track = localTrackData[trackName];
      if (track) {
        track.stop();
        track.close();
        localTrackData[trackName] = undefined;
      }
    }

    setRemoteUsers({});
    if (document.querySelector('.remove-playerlist')) {
      document.querySelector('.remove-playerlist').innerHTML = '';
    }

    // leave the channel
    await client.current.leave();
    setLeave(true);
    setShowContentBlock(false);
    setJoinSession(false);
    setRole(null);
  };

  const isInputUserUid = (e) => setUserUid(e.target.value);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (seconds === 0) {
      setSeconds(300);
    }
  }, [seconds])

  const checkTime = useMemo(() => {
    if (Math.floor(seconds % 60) >= 0 && Math.floor(seconds % 60) < 10) {
      return `0${Math.floor((seconds / 60) % 60)}:0${Math.floor(seconds % 60)}`;
    }
    return `0${Math.floor((seconds / 60) % 60)}:${Math.floor(seconds % 60)}`;
  }, [seconds]);

  return (
    <div className="form-container">
      <form>
        {role === 'host' && <div className="location-href">{window.location.href}</div>}
        {!joinSession ? (
          <>
            <div className="form-inputs">
              <div className="form-input">
                <p className="input-label">User ID(name)</p>
                <input type="text" placeholder="Enter the user ID(name)" required onInput={isInputUserUid} />
              </div>
            </div>
            <button className="button" value="host" onClick={isHandlerChooseRole}>Join as host</button>
            <button className="button" value="audience" onClick={isHandlerChooseRole}>Join as audience</button>
          </>
        ) : (
            <div className="join-session__title">
              <button className="button" onClick={isHandlerLeave}>Leave</button>
            </div>
        )}
      </form>
      {showContentBlock && (
        <>
          <div className="lot-block">
            <div className="current-lot">
              <p className="lot-title">Lot #27300035, {checkTime} </p>
              <img src={lot} alt="lot"/>
            </div>
            <div className="next-lot">
              <p className="lot-title">next lot</p>
              <img src={lot2} alt="lot"/>
            </div>
          </div>
          <div className="content-block">
            <div className="video-block">
              {role === 'host' ? (
                <>
                  <div id="local-player-name" ref={localName} />
                  <div id="local-player" />
                </>
              ) : (
                <div className="remove-playerlist" ref={ref} />
              )}
            </div>
            <div className="chat-block">
              <MessageChat uid={userUid} leave={leave} role={role} />
            </div>
          </div>
        </>
      )}

    </div>
  )
}

export default VideoStream;
