import React from 'react';
import VideoStream from './components/VideoStream';

function App() {
  return (
    <div className="container">
      <VideoStream />
    </div>
  );
}

export default App;
