import React, { useState, useEffect, useRef } from 'react';
import AgoraRTM from "agora-rtm-sdk";
import './style.scss';

const appID = process.env.REACT_APP_AGORA_ID || '706ae9e9433a43d3a8ad75fc2166450f';

const MessageChat = ({ uid, leave, role }) => {
  const client = useRef(AgoraRTM.createInstance(appID));
  const channel = useRef(client.current.createChannel('demoChannel'));
  const ref = useRef(null);

  useEffect(() => {
    client.current.on('MessageFromPeer', (message, peerId) => {
      document
        .getElementById('log')
        .appendChild(document.createElement('p'))
        .append('Message from: ' + peerId + ' Message: ' + message);
        ref.current.scrollTop = ref.current.scrollHeight + 10;
    });

    channel.current.on('ChannelMessage', (message, memberId) => {
      document
        .getElementById('log')
        .appendChild(document.createElement('div'))
        .innerHTML = `<strong>${memberId}</strong>:  ${message.text}`;
        ref.current.scrollTop = ref.current.scrollHeight + 10;
    });

    channel.current.on('MemberJoined', (memberId) => {
      document
        .getElementById('log')
        .appendChild(document.createElement('p'))
        .append(memberId + ' joined the channel');
        ref.current.scrollTop = ref.current.scrollHeight + 10;
    });

    channel.current.on('MemberLeft', (memberId) => {
      document
        .getElementById('log')
        .appendChild(document.createElement('p'))
        .append(memberId + ' leave the channel');
      ref.current.scrollTop = ref.current.scrollHeight + 10;
    });

    client.current.login({
        uid: `${uid}`,
        token: null,
      }).then(() => {
      channel.current
        .join()
        .then(() => {
          document
            .getElementById('log')
            .appendChild(document.createElement('div'))
            .append('You have successfully joined channel ' + channel.current.channelId);
        })
    });

  }, []);

  const handlerSendChannelMessage = () => {
    const channelMessage = document.getElementById('channelMessage').value.toString();

    channel.current.sendMessage({ text: channelMessage }).then(() => {
      document.getElementById('channelMessage').value = '';
      document.getElementById('channelMessage').value = '';
      document
        .getElementById('log')
        .appendChild(document.createElement('div'))
        .innerHTML = `<strong>${uid}</strong>:  ${channelMessage}`
    });

    ref.current.scrollTop = ref.current.scrollHeight + 10;
  };

  const handleIncrementCurrentBind = (e) => {
    const count = e.target.value;

    channel.current.sendMessage({ text: count }).then(() => {
      document.getElementById('channelMessage').value = '';
      document
        .getElementById('log')
        .appendChild(document.createElement('div'))
        .innerHTML = `<strong>${uid}</strong>:  ${count}`
    });

    ref.current.scrollTop = ref.current.scrollHeight + 10;
  }

  const isLeave = () => {
    channel.current.leave();
  }

  window.onunload = () => isLeave();

  useEffect(() => {
    leave && isLeave();
  }, [leave]);

  return (
    <div className="wrapper">
      <div className="header-title__block">
        <p className="title">Current bid: 100$</p>
      </div>
      {role !== 'host' && (
        <div className="button-block">
          <button
            className="button button-increment"
            value="increment by 10$"
            onClick={handleIncrementCurrentBind}
          >
            Inc $10
          </button>
          <button
            className="button button-increment"
            value="increment by 25$"
            onClick={handleIncrementCurrentBind}
          >
            Inc $25
          </button>
          <button
            className="button button-increment"
            value="increment by 50$"
            onClick={handleIncrementCurrentBind}
          >
            Inc $50
          </button>
        </div>
      )}
      <div id="log" ref={ref} />

      <div className="message-block">
        <textarea
          type="text"
          placeholder="Type a message"
          id="channelMessage"
        />
        <button
          className="button button-sends"
          id="send_channel_message"
          onClick={handlerSendChannelMessage}
        >send</button>
      </div>
    </div>
  )
};

export default MessageChat;
